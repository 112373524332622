import { Component, OnInit ,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute} from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from './shared/authentication.service';
import { StorageService } from 'app/core/services/storage.service';
import swal from'sweetalert2';

@Component({
   selector: 'ms-loginone-session',
   templateUrl:'./loginone-component.html',
   styleUrls: ['./loginone-component.scss'],
   encapsulation: ViewEncapsulation.None,
})

export class LoginoneComponent implements OnInit {

   public loginForm: FormGroup;
   public submitted: Boolean = false;
   public loading = false;
   public info = JSON;
   public info2 = JSON;
   returnUrl: string;

   constructor(
      private router: Router,
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private authenticationService : AuthenticationService,
      private storageService: StorageService,
      private toastr: ToastrService,
      public translate: TranslateService
   ) {
      this.toastr.toastrConfig.timeOut = 5000;
   }

   ngOnInit() {
      // reset login status
      this.authenticationService.logout();

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

      // Form validations
      this.loginForm = this.formBuilder.group({
         user: ['', [Validators.email, Validators.required]],
         password: ['', [Validators.required]],
      });
   }

   // convenience getter for easy access to form fields
   get f() { return this.loginForm.controls; }

   // when email and password is correct, user logged in.
   logIn(){
      //this.authService.loginUser(value);
      this.submitted = true;
      if (this.loginForm.invalid){
         // Efecto Shake
         document.getElementById('animation-object').className = '';
         document.getElementById('animation-object').classList.add('animated', 'shake');
         setTimeout(()=>{ document.getElementById('animation-object').className = ''; }, 1000);
         return;
      }

      this.loading = true;
      this.loginForm.value.email = this.loginForm.value.user;
      this.authenticationService.login(this.loginForm.value).subscribe((data)=>{
         // this.textLoading = this.translations['entering'] + "...";
         this.loading = false;

         // Correct Login
         let info = data.json();

         this.authenticationService.login2(this.loginForm.value).subscribe((data2)=>{
            // this.textLoading = this.translations['entering'] + "...";
            this.loading = false;

            // Correct Login
            let info2 = data2.json();
            this.correctLogin2(info2['data']);


          }, err =>{
            console.log("err",err);
            this.loading = false;
            this.loginForm.controls['password'].setValue("");
            if (err.status == 500){
               swal.fire({ icon: 'error', title: 'Ups...', text: '¡Ha ocurrido un error inesperado con el servidor! Intentalo de nuevo más tarde.'});
            }else{
               let message = JSON.parse(err._body).error;
               if (JSON.parse(err.status) == 404){
                  this.submitted = false;
                  this.toastr.error(message);
               }
              //  else{
              //     this.toastr.error("Ha ocurrido un error inesperado.");
              //  }
            }
          }
         );


         this.toastr.success('Bienvenido(a) a Multientrega', 'Ingresando...');
         setTimeout(() => {
            this.correctLogin(info.data);
         }, 1000);
       }, err =>{
         console.log("err",err);
         this.loading = false;
         this.loginForm.controls['password'].setValue("");
         if (err.status == 500){
            swal.fire({ icon: 'error', title: 'Ups...', text: '¡Ha ocurrido un error inesperado con el servidor! Intentalo de nuevo más tarde.'});
         }else{
            let message = JSON.parse(err._body).error;
            if (JSON.parse(err.status) == 404){
               this.submitted = false;
               this.toastr.error(message);
            }else{
               this.toastr.error("Ha ocurrido un error inesperado.");
            }
         }
       }
      );
   }


   private correctLogin(data: any){
      this.storageService.setCurrentSession(data);
      // login successful so redirect to return url
      this.router.navigateByUrl(this.returnUrl);
  }

  private correctLogin2(data: any){
   this.storageService.setCurrentSession2(data);
   // login successful so redirect to return url

}

}
