// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
  domain_back: 'https://desarrolloback.multientregapanama.com/api/',
  domain_cors: '/api/',
  domain_admin: 'https://admultientrega.developapp.co/api/',
  domain_img: 'https://api.multientregapanama.com/api/image/view?url=',
  test: '',
  test2: ''
};
